// BASE
.activityLink {
    height: 53px;
    width: 48px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    background-origin: content-box;
    position: relative;
}
.activitiyLink__activeBar {
    width: 0px;
    height: 0;
    position: absolute;
    left: 0px;
    transition: all 0.25s ease-in-out;
}
.activityLink--active .activitiyLink__activeBar {
    width: 3px;
    height: 53px;
}
.activityLink--expanded {
    width: inherit;
    justify-content: start;
}

// ICON
.activityLink__icon {
    font-size: 24px;
}
.activityLink--expanded .activityLink__icon {
    margin: 0 10px;
}

// TEXT
.activityLink__text {
    margin-right: 10px;
}

// BADGE
.activityLink__badge {
    position: absolute;
    bottom: -8px;
    right: -8px;
    // transition: all 0.15s;
}
.activityLink--expanded .activityLink__badge {
    right: 2px;
    // position: relative;
    // bottom: inherit;
    // right: inherit;
}
