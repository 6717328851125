.activityBar {
    padding-bottom: 5px;
    width: 48px;
    transition: width .15s;
}
.activityBar--expanded {
    width: 200px;
}

.activityBar__footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.activityBar--expanded .activityBar__footer {
    justify-content: flex-end;
}
