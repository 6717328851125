.activityPage {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.activityPage__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
}

.activityPage__footer {
    margin-bottom: 20px;
}