.no-match-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .no-match-container {
    width: 35rem; 
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 30px rgba(0,0,0,0.75);
  }
  
  .no-match_upper {
    font-weight: bold;
    font-size: 40px;
    color: #FFF;
  }
  
  .no-match_mid {
    font-size: 30px;
    padding: 20px;
  }
  
  .no-match_lower {
    background: #EEE;
    font-size: 20px;
    padding: 10px;
  }