body {
    margin: 0px !important;
    padding: 0;
    font-family: sans-serif;
}

body,
html {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
